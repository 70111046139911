"use client"
import CardSmoll from "@/components/card-smoll";
import {Htag} from "@/components/ui/htag";
import CardService from "@/components/card-service";
import Line from "@/components/ui/line";
import {Accordion,} from "@/components/ui/accordion"
import React, {useContext, useEffect, useRef, useState} from "react";
import IconTexeLine from "@/components/icon-texe-line";
import AccordionService from "@/components/accordion";
import {Popup} from "@/components/popup";
import {CarouselItem} from "@/components/ui/carousel";
import CaruselService from "@/components/carusel";
import Start from "@/components/start-block";
import AnalyzeBlock from "@/components/analyze-block";
import {PageData} from "@/intefaces/page.props";
import {PopupContext} from "@/context/PopupContext";
import {P} from "@/components/ui/P";
import {initializeCookies} from "@/helpers/cookies";
    import Stages from "@/components/stages";
import AboutObject from "@/components/about-object";
import {Fids} from "@/components/fids";

export default function DynamicPage(data: PageData) {
    const [selectedObjectIndex, setSelectedObjectIndex] = useState(0);
    const cardRef = useRef<HTMLDivElement>(null);
    const analyzedRef = useRef<HTMLDivElement>(null);
    const selectedObject = data?.cardObject?.[selectedObjectIndex];
    const {openPopup, closePopup, isPopupOpen, currentData, afterActionPopup} = useContext(PopupContext);


    useEffect(() => {
        let delay = 25000;
        if (afterActionPopup) {
            delay = 15000;
            const timer = setTimeout(() => {
                openPopup('action-analyze');
            }, delay);
        }
    }, [afterActionPopup, openPopup]);


    useEffect(() => {
        const initCookies = async () => {
            await initializeCookies();
        };
        initCookies();
    }, []);

    const handleIconClick = (index: number) => {
        setSelectedObjectIndex(index);
        if (cardRef.current) {
            cardRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
    };
    const scrollToAnalyzed = () => {
        if (analyzedRef.current) {
            analyzedRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
    };
    const isSellPage = data?.id.includes('sell');
    const isDolyPage = data?.id.includes('doli');
    const isBuyPage = data?.id.includes('buy');



    return (
        <div>
            <div className='px-4 py-12 xl:mb-14'>
                <Start
                    title={data?.title}
                    description={data?.description}
                    btname={data?.btname}
                    cards={data.cardsTop}
                    onCardClick={scrollToAnalyzed}
                />
            </div>
            <CaruselService className="md:hidden  mb-14 p-3">
                {data.cardsTop?.map((data, index) => (
                    <CarouselItem key={index} className="py-4 basis-44">
                        <CardSmoll
                            cards={data}
                            key={index}
                            onCardClick={scrollToAnalyzed}

                        />
                    </CarouselItem>
                ))}
            </CaruselService>
            {isBuyPage && <Fids pagination={8}/>}
            <div className="max-w-custom mx-auto" ref={analyzedRef}>
                {isSellPage && !isDolyPage ? <> <AnalyzeBlock/> <Line/> </> : null}
                <Htag tag={"h2"} className={'px-3 mt-12'}>Наши услуги</Htag>
                <P className='mt-3 px-3'> Эксперты `Мурман Клик` помогут
                    вам найти идеальное решение и обеспечат юридическую поддержку на всех этапах сделки.</P>
                <div className='flex flex-col gap-3 lg:flex-row my-14'>
                    {data.cardsService?.map((service, index) => (
                        <CardService key={index}
                                     {...service}
                                     onCardClick={scrollToAnalyzed}
                        />
                    ))}
                </div>
                <Line/>
                {isSellPage ? <> <Stages/> <Line/> </> : null}
                <div className="my-14 px-3">
                    <Htag tag={"h2"}>С какими объектами мы работаем</Htag>
                    <div className="grid grid-cols-2 gap-5 lg:grid-cols-6 mt-14">
                        {data?.withObjet?.map((service, index) => (
                            <IconTexeLine
                                key={index}
                                size='m'
                                className=' flex-col gap-3 content-center p-4 pt-6 rounded-md shadow-custom hover:bg-gray-50'
                                description={service.title}
                                path={service.path}
                                onClick={() => handleIconClick(index)}
                            />
                        ))}
                    </div>
                </div>
                <Line/>
                <AboutObject ref={cardRef} {...selectedObject} className={'my-14'}/>
                <Line/>
                <div className="px-3 my-14">
                    <Htag tag={"h2"} className={'mb-6'}>Частые вопросы</Htag>
                    <Accordion type="single" collapsible>
                        {selectedObject?.questions?.map((item) => (
                            <AccordionService key={item.id} {...item}/>
                        ))}
                    </Accordion>
                </div>
                <Line/>
            </div>
            {isPopupOpen && currentData && (
                <Popup isOpen={isPopupOpen} onClose={closePopup} data={currentData}/>
            )}
        </div>
    );
}
