"use client"

import {Htag} from "./ui/htag";
import {P} from "./ui/P";
import Chart from "./chart";
import FormOrder from "./form-order";
import Line from "./ui/line";
import {ResponseAnalyze} from "@/intefaces/api.props";
import {useEffect, useRef} from "react";

export default function AnalyzeResult(data: ResponseAnalyze) {
    const analyzedRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (analyzedRef.current) {
                analyzedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Line/>
            <div className='max-w-custom mx-auto my-14 px-3'>
                <Htag tag='h2'>Отчет о стоимости недвижимости</Htag>
                <P className="mt-3">{data.address}</P>
                <div className='grid grid-cols-1 mt-12 gap-2 xl:grid-cols-2'>
                    <div>
                        <P size='s'>Диапазон цен</P>
                        <P size='g'>{`${data.priceMin} ₽ - ${data.priceMax} ₽`}</P>
                    </div>
                    <div>
                        <P size='s'>Изменение за 12 мес</P>
                        <P size='g'>{`${data.annualPriceChangePercent} %`}</P>
                    </div>
                    <div>
                        <P size='s'>Средне рыночная</P>
                        <P size='g'>{`${data.price} ₽`}</P>
                    </div>
                    <div>
                        <P size='s'>Изменение за 3 мес</P>
                        <P size='g'>{`${data.threeMonthPriceChangePercent} %`}</P>
                    </div>
                    <div>
                        <P size='s'>Цена за 1 кв/м</P>
                        <P size='g'>{`${data.priceMeter} ₽/м²`}</P>
                    </div>
                </div>
                <P size='s' className='mt-12 my-3'>Динамика изменений:</P>
                <Chart analyticsData={data.analytics}/>
                <Htag className='mt-8' tag={"h2"}>Продайте недвижимость уже сегодня</Htag>
                <P className="my-3 mb-8">Оставьте заявку и получите выгодное предложение по цене! Мы гарантируем безопасность сделки и предоставляем полное юридическое сопровождение на всех этпах продажи недвижимости. </P>
                <FormOrder ref={analyzedRef} address={data.address} formname={'Order from analyze page'}/>
            </div>
        </>
    );
}
