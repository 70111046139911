"use client";
import {cn} from "@/lib/utils";
import {P} from "./ui/P";
import {Icon} from "./ui/icon";

interface IconTexeLineProps {
    description: string;
    className?: string;
    size: 's' | 'm' | 'l';
    path: string;
    onClick?: () => void;

}

export default function IconTexeLine({description, className, size, path, onClick}: IconTexeLineProps) {
    return (
        <div className={cn('flex gap-4 items-center', className)} onClick={onClick}>
            <Icon size={size} path={path} alt={description}/>
            <P size={size}>{description}</P>
        </div>
    )
}