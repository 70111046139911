import {AccordionContent, AccordionItem, AccordionTrigger,} from "@/components/ui/accordion"
import {P} from "./ui/P";

export interface AccordionServiceProps{
    id: string;
    question: string;
    answer: string;
}


export default function AccordionService({question,answer,id} : AccordionServiceProps) {
    return (
            <AccordionItem value={id}>
                <AccordionTrigger><P>{question}</P></AccordionTrigger>
                        <AccordionContent>
                             <P size='s'>{answer}</P>
                        </AccordionContent>
            </AccordionItem>

    );
  }
