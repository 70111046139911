import {Carousel, CarouselApi, CarouselContent,} from "@/components/ui/carousel"
import React, {useEffect, useState} from "react";
import {cn} from "@/lib/utils";
import Dots from "./ui/dots";
import {P} from "@/components/ui/P";

interface CaruselServiceProps {
    children: React.ReactNode;
    className?: string;
    title?: boolean;
}

export default function CaruselService({children, className,title}: CaruselServiceProps) {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!api) {
            return;
        }

        const updateCarousel = () => {
            setCount(api.scrollSnapList().length);
            setCurrent(api.selectedScrollSnap() + 1);
        };

        updateCarousel();
        api.on('select', updateCarousel);

        return () => {
            api.off('select', updateCarousel); // Убеждаемся, что функция возвращает void
        };
    }, [api]);

    return (
        <Carousel className={cn("w-full", className)} setApi={setApi}>
            {title && <P size={'s'} className={'px-3 mb-6'}>Услуга {current} из {count} {'>>>'}</P>}
            <CarouselContent>
                {children}
            </CarouselContent>
            <Dots count={count} current={current - 1}/>
        </Carousel>
    );
}