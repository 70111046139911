import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {popupData} from "@/data/popup-data";
import {PopupData} from "@/intefaces/popup.prop";
import {sendGTMEvent} from '@next/third-parties/google'


interface PopupContextType {
    isPopupOpen: boolean;
    afterActionPopup: boolean;
    currentData: PopupData | null;
    openPopup: (dataId?: string) => void;
    closePopup: () => void;
    setAfterActionPopup: (value: boolean) => void;}

export const PopupContext = createContext<PopupContextType>({
    isPopupOpen: false,
    currentData: null,
    openPopup: () => {
        console.warn("openPopup function not initialized");
    },
    afterActionPopup: false,
    setAfterActionPopup: (value: boolean) => {
        console.warn("setAfterActionPopup function not initialized");
    },
    closePopup: () => {
        console.warn("closePopup function not initialized");
    }
});

interface PopupProviderProps {
    children: ReactNode;
}

export const PopupProvider = ({children}: PopupProviderProps) => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<PopupData | null>(null);
    const [afterActionPopup, setAfterActionPopup] = useState<boolean>(false);

    const openPopup = useCallback((dataId?: string) => {
        if (dataId !== undefined) {
            const data = popupData.find(p => p.id === dataId);
            setCurrentData(data? data : null);
            setPopupOpen(true);
            // console.log('Open pop-up', popupData[dataIndex].event);
            // sendGTMEvent({ event: data?.event, value: data?.value })
        } else {
            console.error('Index out of range or popUp data is undefined');
        }
    }, [setCurrentData, setPopupOpen]); // Включаем все переменные, которые используются в функции

    useEffect(() => {
    }, [isPopupOpen, currentData]);

    const closePopup = useCallback(() => {
        // console.log("Closing popup");
        setPopupOpen(false);
        setCurrentData(null);
    }, []);

    return (
        <PopupContext.Provider value={{isPopupOpen,
            afterActionPopup,
            setAfterActionPopup,
            currentData,
            openPopup,
            closePopup}}>
            {children}
        </PopupContext.Provider>
    );
};
