"use client";

import {Form, FormControl, FormField, FormItem, FormLabel} from "@/components/ui/form";
import {zodResolver} from "@hookform/resolvers/zod"
import {useForm} from "react-hook-form"
import {z} from "zod"
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import cn from 'classnames';

import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue,} from "@/components/ui/select"
import {getAnalyzeResult} from "@/helpers/api";
import {ResponseAnalyze} from "@/intefaces/api.props";
import {forwardRef} from "react";
import {sendGTMEvent} from "@next/third-parties/google";

const formSchema = z.object({
    address: z.string().min(10, {
        message: "Введите адрес",
    }),
    roomType: z.string().nonempty({
        message: "Количество комнат"
    })
});

interface AnalyzeFormProps {
    setAnalyze: (data: ResponseAnalyze) => void;
    setError: (error: Error | null) => void;
    setAddress: (address: string) => void;
}

const AnalyzeForm = forwardRef<HTMLDivElement, AnalyzeFormProps>(({setAnalyze, setError, setAddress}, ref) => {
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            address: "",
            roomType: ""
        },
    });

    type FormData = z.infer<typeof formSchema>;

    async function onSubmit(values: FormData) {
        try {
            const data = await getAnalyzeResult(values);
            setAnalyze(data);
            console.log("Submitting", values);
            setError(null);
            sendGTMEvent({ event: 'analyze-form', value: 'analyze-tag' })
            form.reset({
                address: "",
                roomType: ""
            });
        } catch (error) {
            setAddress(values.address)
            if (error instanceof Error) {
                console.error("Error during data fetch:", error);
                if (error.message.includes('404')) {
                    setError(new Error("404"));
                } else if (error.message.includes('500')) {
                    setError(new Error("500"));
                } else {
                    setError(error);
                }
            } else {
                setError(new Error("An unknown error occurred"));
            }
        }
    }

    return (

        <div className={cn('max-w-custom mx-auto mt-8 ')}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="">
                    <div className="flex flex-col space-x-0 xl:flex-row xl:space-x-2 xl:items-end">
                        <FormField
                            control={form.control}
                            name="address"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel>Введите адрес</FormLabel>
                                    <FormControl ref={ref}>
                                        <Input placeholder="Мурманск Александрова 30/3" {...field}
                                               className='xl:w-[692px]'/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="roomType"
                            render={({field}) => (
                                <FormItem className="mt-8">
                                    <FormControl>
                                        <Select
                                            value={field.value}
                                            onValueChange={field.onChange}
                                        >
                                            <FormLabel>Кол-во комнат</FormLabel>
                                            <SelectTrigger className='text-sm xl:w-[285px]'>
                                                <SelectValue placeholder="Комн"/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="Студия">Студия</SelectItem>
                                                <SelectItem value="1">1</SelectItem>
                                                <SelectItem value="2">2</SelectItem>
                                                <SelectItem value="3">3</SelectItem>
                                                <SelectItem value="4">4</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    {/* <FormMessage>{form.formState.errors.roomType?.message}</FormMessage> */}
                                </FormItem>
                            )}
                        />
                        <Button variant={'outline'} type="submit" size={'lg'} className="mt-8 ">Узнать</Button>
                    </div>
                </form>
            </Form>
        </div>
    );
});
AnalyzeForm.displayName = 'AnalyzeForm';
export default AnalyzeForm;
