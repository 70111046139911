import React from 'react';
import {P} from './ui/P';

interface ErrorAnalyzeProps {
    error: Error | null
    address?: string
}

export default function ErrorAnalyze({error, address}: ErrorAnalyzeProps) {
    function notContent() {
        if (error?.message === '404') {
            return (
                <div className=' border-2 rounded-md p-1'>
                    <P size='l'> Данные по адресу: {address} не найдены</P>
                    <P> Проверьте корректность введенных данных и повторите запрос</P>
                </div>
            );
        }
        if (error?.message === '500') {
            return (
                <div className=' border rounded-md'>
                    <P> На данный момент сервис не доступен, проводятся технические работы</P>
                    <P> Приносим свои извенения за неудобства</P>
                </div>
            );
        }
    }

    return (
        <div className='max-w-custom mx-auto px-3 mb-12'>
            {notContent()}
        </div>
    );
}