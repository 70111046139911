import {PopupData} from "@/intefaces/popup.prop";

export const popupData: PopupData [] = [{
    id: 'sell',
    title: 'Получите выгодное предложение. Продайте квартиру без показов и стресса, уже сегодня!',
    discription: 'Оставьте заявку или позвоните',
    event: 'popup-srochniy',
    value: 'open-popup-srochniy'
},
    {
        id: 'avans',
        title: 'Получите аванс уже сегодня',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    },
    {
        id: 'usual-sell',
        title: 'Поможем продать вашу недвижимость',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'inspection',
        title: 'Получите юридическую проверку недвижимости',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: "mortgage",
        title: 'Получите Консультацию ипотечного брокера',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'buy',
        title: 'Найдем для вас лучшее предложение на рынке недвижимости!',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'support',
        title: 'Закажите сопровождение сделки недвижимости',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    },{
        id: 'write',
        title: 'Доверьте Печать договора купли продажи вашей недвижимости профессионалам',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'about',
        title: 'Получите бесплатную консультацию',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'sell-doli-v-kvartire',
        title: 'Продайте долю в кварире без стресса, с полным юридическим сопровождением!',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'sell-rooms',
        title: 'Продайте комнату без показов и стресса, уже сегодня!',
        discription: 'Оставьте заявку или позвоните',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'second-visit',
        title: 'Свяжитесь с нами прямо сейчас для консультации или оставьте свой номер, и мы перезвоним вам.',
        discription: 'Нужна помощь? Мы всегда готовы поддержать вас!',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }, {
        id: 'third-visit',
        title: 'Позвоните нам или оставьте заявку, чтобы получить профессиональную консультацию и поддержку.',
        discription: 'Наш опыт — ваш успех!',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    },{
        id: 'action-analyze',
        title: 'Оставьте заявку или позвоните. Вы получите выгодное предложение и подробную консультацию что бы принять правильное решение',
        discription: 'Продайте квартиру уже сегодня',
        event: 'popup-srochniy',
        value: 'open-popup-srochniy'
    }]

