import React, {useState} from "react";
import {P} from "@/components/ui/P";
import {Htag} from "@/components/ui/htag";

export default function Stages({}) {
    const [openItems, setOpenItems] = useState(new Array(items.length).fill(false));

    const toggleItem = (index: number) => {
        const newOpenItems = [...openItems];
        newOpenItems[index] = !newOpenItems[index];
        setOpenItems(newOpenItems);
    };

    return (
        <div className={'my-14 px-3'}>
            <Htag tag={'h2'}>Как мы работаем</Htag>
            <div className={'grid grid-cols-1 gap-6 lg:grid-cols-2 mt-6'}>
                {items.map((item, index) => (
                    <div key={item.id} className={'flex flex-col gap-5 mt-6 max-w-[500px]'}>
                        <div className={'flex items-center gap-4'}>
                            <p className={'text-6xl text-primary font-black'}>{index + 1}</p>
                            <Htag tag={'h3'}>{item.title}</Htag>
                        </div>
                        <div className={'h-[3px] w-[200px] bg-primary'}></div>
                        <P>{item.description}</P>
                        {openItems[index] && <P>{item.description2}</P>}
                        <button className={'text-gray-400 text-start'} onClick={() => toggleItem(index)}>
                            {!openItems[index] ? 'Подробнее...' : 'Скрыть'}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

const items = [
    {
        id: 1,
        title: 'Оставьте заявку или звоните нам',
        description: 'Вы получите подробную консультацию, наши специаличсты ответят на ваши вопросы и помогут выбрать подходящюю услуги.',
        description2: 'Вопросы, связанные с продажей недвижимости, всегда очень важны и требуют профессионального подхода. Наши эксперты подробно объяснят все этапы процесса и предложат наиболее эффективные решения, исходя из ваших потребностей.',
    },
    {
        id: 2,
        title: 'Мы анализируем вашу ситуацию',
        description: 'Анализ включает оценку рыночной стоимости недвижимости, и юридическую проверку документов.',
        description2: 'Мы учитываем все ваши пожелания и требования, чтобы предложить наиболее выгодные и подходящие варианты. Наш анализ основан на многолетнем опыте и глубоких знаниях рынка недвижимости.'
    },
    {
        id: 3,
        title: 'Заключение договора купли продажи',
        description: 'Мы подготовим договор купли-продажи и учтем все ваши пожелания и требования.',
        description2: 'Наши юристы обеспечивают полное сопровождение сделки, если на недвижмимости есть долги или аресты мы разбермся с эти сами! '
    },
    {
        id: 4,
        title: 'Взаиморасчеты',
        description: 'После оценки, согласования условий и подписания договора Вы получаете свои деньги за недвижимость.',
        description2: 'Наше агентство имеет многолетний опыт работы на рынке недвижимости, что позволяет нам уверенно предлагать гарантии качества и надежности. Мы дорожим своей репутацией и стремимся к долгосрочным отношениям с нашими клиентами.'
    }
];
