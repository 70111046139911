import cn from 'classnames';

interface DotsProps {
    count: number;
    current?: number;
	className?: string;
  }

function Dots( { count, current,className} : DotsProps) {
	return (
		
		<div className='text-center p-3'>
			{Array.from({ length: count }, (_, index) => (
				<span key={index} className={cn('h-3 w-3 m-1 bg-gray-200 rounded-full inline-block', className,{ ['bg-secondary w-6']: index === current })}></span>
			))}
		</div>
	);
}

export default Dots;