import {Button} from "@/components/ui/button";
import {Carousel, CarouselApi, CarouselContent, CarouselItem} from "@/components/ui/carousel";
import CaruselService from "@/components/carusel";
import React, {useEffect, useState} from "react";
import {cn} from "@/lib/utils";
import {P} from "@/components/ui/P";
import Dots from "@/components/ui/dots";

export interface Flat {
    id: number;
    title: string;
    price: string;
    address: string;
    images: Image[];
}

export interface Image {
    id: number;
    url: string;
}

export const CardFlat = ({title, price, address, images}: Flat) => {
    const path = images && images.length > 0 ? images[0].url : "not photo";
    const pagination = 7;
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!api) {
            return;
        }

        const updateCarousel = () => {
            setCount(api.scrollSnapList().length);
            setCurrent(api.selectedScrollSnap() + 1);
        };

        updateCarousel();
        api.on('select', updateCarousel);

        return () => {
            api.off('select', updateCarousel); // Убеждаемся, что функция возвращает void
        };
    }, [api]);
    return (
        <div className={' mx-auto rounded-md p-3 flex flex-col gap-3 '}>
            <Carousel className={''} setApi={setApi}>
                <CarouselContent>
                    {images.slice(0, pagination).map((image, index) => (
                        <CarouselItem key={index} className={''}>
                            <img
                                src={image.url}
                                alt={`Slide ${index + 1}`}
                                className={'h-[300px] w-full rounded-md cursor-pointer '}
                            />
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <div className={'absolute bottom-0 left-1/2 transform -translate-x-1/2 whitespace-nowrap'}>
                    <Dots count={count} current={current - 1} className={'m-0'}/>
                </div>
            </Carousel>
            <Button variant={'outline'} className={'w-full h-10 '}>Посмотреть</Button>
            <div className={'flex flex-col gap-2'}>
                <p className={'text-secondary font-medium'}>{title}</p>
                <p className={'font-medium'}>{price} руб.</p>
                <p className={'max-w-40 font-light'}>{address}</p>
            </div>
        </div>
    );
};