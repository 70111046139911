import React, {forwardRef} from 'react';
import {P} from './ui/P';
import IconTexeLine from './icon-texe-line';
import {cn} from '@/lib/utils';
import {Htag} from './ui/htag';
import {CardServiceProps} from "@/components/card-service";
import FormOrder from "@/components/form-order";


const AboutObject = forwardRef<HTMLDivElement, CardServiceProps>(({
                                                                      className,
                                                                      description,
                                                                      title,
                                                                      fullText,
                                                                      tag,
                                                                      children,
                                                                  }, ref) => {

    return (
            <div ref={ref} className={cn("flex flex-col justify-between px-3 lg:flex-row", className)}>
                <div className='max-w-[600px]'>
                    <Htag tag={'h2'}>{title}</Htag>
                    <P size='s' className='mt-8'>{description}</P>
                    <div className='flex flex-col gap-4 mt-8'>
                        {tag?.map((t, index) => (
                            <IconTexeLine size='s' key={index} className='mb-3' description={t.text}
                                          path={"/plus.png"}/>
                        ))}
                        <P className='mb-4'>{fullText}</P>
                        {children}
                    </div>
                </div>
                <div className={'max-w-[550px]'}>
                    <Htag tag={'h2'} className={'text-secondary'}>Закажите консультацию </Htag>
                    <FormOrder formname={title} className={' mt-8'}/>
                </div>
            </div>
    );
});

AboutObject.displayName = 'CardService';
export default AboutObject;

