"use client"

import {zodResolver} from "@hookform/resolvers/zod"
import {useForm} from "react-hook-form"
import {z} from "zod"

import {Button} from "@/components/ui/button"
import {Form, FormControl, FormField, FormItem, FormLabel,} from "@/components/ui/form"
import {Input} from "@/components/ui/input"
import {useMask} from "@react-input/mask";
import {postOrder} from "@/helpers/api";
import {FormDataProps} from "@/intefaces/form-data.props";
import React, {forwardRef, useState} from "react";
import {P} from "@/components/ui/P";
import {sendGTMEvent} from "@next/third-parties/google";
import cn from "classnames";
import AnalyzeForm from "@/components/form-analyze";

interface FormOrderProps{
    address?: string;
    formname?: string;
    className?: string;

}

const FormOrder= forwardRef<HTMLDivElement, FormOrderProps>(({address,formname,className},ref) => {

        const [isSubmitted, setIsSubmitted] = useState(false);

    const formSchema = z.object({
        name: z.string().min(4, {
            message: "Введите адрес",
        }),
        phone: z.string().nonempty({
            message: "Количество комнат"
        })
    });

    const inputRef = useMask({mask: '+7 (___) ___-__-__', replacement: {_: /\d/}});

    // type FormData = z.infer<typeof formSchema>;

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            phone: ""
        },
    });


    async function onSubmit(values: FormDataProps) {
        console.log("Submitting", values);
        try {
            const newValues = {...values, formname: formname, address: address};
            const response = await postOrder(newValues);
            sendGTMEvent({ event: 'form-order', value: 'form-order' })
            // @ts-ignore
            if (response.ok) {
                setIsSubmitted(true);
                form.reset({
                    name: "",
                    phone: ""
                });
            } else {
                throw new Error("Network response was not ok");
            }
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    }

    if (isSubmitted) {
        return (
            <div className="text-center">
                <P size={'g'} className="text-2xl font-semibold">Данные отправлены</P>
                <P size={'s'} className="text-2xl font-semibold">Менеджер свяжется с вам в ближайшее время</P>

            </div>
        );
    }
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-8",className)}>
                <FormField
                    control={form.control}
                    name="name"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Ваше имя</FormLabel>
                            <FormControl>
                                <Input placeholder="Владимир Иванов" {...field} type="name"/>
                            </FormControl>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="phone"
                    render={({field}) => (
                        <FormItem className="mt-8">
                            <FormLabel>Ваш номер телефона</FormLabel>
                            <FormControl ref={ref}>
                                <Input placeholder="+7(999)000-00-00" {...field} type="phone"/>
                            </FormControl>
                        </FormItem>
                    )}
                />
                <Button variant='outline' type="submit" size='lg' className="mt-8">Отправить</Button>
            </form>
        </Form>
    );
});
FormOrder.displayName = 'FormOrder';
export default FormOrder;