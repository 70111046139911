'use client'

import React, {useEffect} from 'react';
import DynamicPage from '@/dynamic/dynamic-page';
import {PopupProvider} from "@/context/PopupContext";
import {PageData} from "@/intefaces/page.props";
import {setApiAddress} from "@/helpers/api";
import NotFound from "next/dist/client/components/not-found-error";

interface ClientPageProps {
    page: PageData | undefined;
}

export default function ClientPage({page}: ClientPageProps) {


    useEffect(() => {
        const response = setApiAddress()
    }, []);

    return (
        <PopupProvider>
            <div>
                {page ? (
                    <DynamicPage {...page}/>
                ) : (
                    <NotFound/>
                )}
            </div>
        </PopupProvider>
    );
}
