import {Analytics} from '@/intefaces/api.props';
import {cn} from '@/lib/utils';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


interface ChartProps {
    analyticsData: Analytics[];
    className?: string;
}

export default function Chart({analyticsData, className}: ChartProps) {

    if (!analyticsData || analyticsData.length === 0) {
        return <div className={cn('h-96', className)}>Данные не загружены</div>;
    }

    const data: ChartData<'line', number[], string> = {
        labels: analyticsData.map(item => new Date(item.date).toLocaleDateString()),
        datasets: [{
            label: 'Цена в миллионах ₽',
            data: analyticsData.length > 0 ? analyticsData.map(item => item.avgPrice / 1000000) : Array(15).fill(null).map(() => Math.random() * 10),
            backgroundColor: 'rgba(66, 123, 125, 0.5)',
            borderColor: '#039549',
            borderWidth: 2,
        }]
    };

    const options: ChartOptions<'line'> = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    precision: 0,
                    stepSize: 1, // This will force the scale to increment in whole steps
                    callback: (value: any) => {
                        if (value % 1 === 0) { // Check if the value is an integer
                            return `${value} млн ₽`;
                        }
                    }
                }
            }
        }
    };

    return (
        <div>
            <Line className={cn('h-96', className)} data={data} options={options}/>
        </div>
    );
};
  



