import React, {forwardRef, ReactNode, useContext, useEffect, useState} from 'react';
import {P} from './ui/P';
import {Button} from './ui/button';
import IconTexeLine from './icon-texe-line';
import {cn} from '@/lib/utils';
import {AccordionServiceProps} from './accordion';
import {Htag} from './ui/htag';
import {Icon} from './ui/icon';
import {PopupContext} from "@/context/PopupContext";

export interface Tag {
    text: string;
}

export interface CardServiceProps {
    children?: ReactNode;
    className?: string;
    description: string;
    title: string;
    fullText: string;
    tag: Tag[];
    questions?: AccordionServiceProps [];
    index?: number | undefined;
    path: string;
    action?: string;
    onCardClick?: () => void;
    price?: string;

}
const useMediaQuery = (query:string) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => setMatches(media.matches);
        media.addListener(listener);

        return () => media.removeListener(listener);
    }, [matches, query]);

    return matches;
};
const CardService = forwardRef<HTMLDivElement, CardServiceProps>(({
                                                                      path,
                                                                      className,
                                                                      description,
                                                                      title,
                                                                      fullText,
                                                                      tag,
                                                                      children,
                                                                      index,
                                                                      action,
                                                                      onCardClick,
                                                                      price
                                                                  }, ref) => {
    const {openPopup} = useContext(PopupContext); // Используем контекст для вызова openPopup
    const isLgScreen = useMediaQuery('(min-width: 1024px)');
    const [active, setActive] = useState(isLgScreen);

    useEffect(() => {
        setActive(isLgScreen);
    }, [isLgScreen]);

    const handleClick = () => {
        console.log("action:", action);
        if (action !== undefined) {
            if (action === 'scroll') {
                if (onCardClick) {
                    onCardClick(); // Вызываем функцию onCardClick если она передана и action === 10
                }
            }
            openPopup(action); // Вызываем openPopup с action из карточки
        }
    };
    const openCard = () => {
        if (!isLgScreen) {
            setActive(prevActive => !prevActive);
        }
    }
    return (
        <div ref={ref} tabIndex={index} className={cn(`p-3 rounded-md xl:min-w-[390px]`)}>
            <div className={cn("flex flex-col gap-4 ", className)}>
                <div onClick={openCard}
                     className='flex gap-2 items-center bg-secondary p-4 rounded-md shadow-custom h-24 justify-between '>
                    <div className={'flex gap-2 items-center'} >
                    <Icon size='l' path={path} alt={title} className='hover:border-primary/80'/>
                    <Htag className=' text-white' tag={'h3'}>{title}</Htag>
                </div>
                    <Icon size={'l'} path={'/arrow.png'} alt={'arrow'} className={cn('lg:hidden',{'rotate-180': active})}/>
                </div>
                <div className={cn('flex flex-col gap-4',{'hidden': !active})}>
                    <div className={cn('rounded-md  p-1 shadow-custom')}>
                        <P size='s' className=' p-4 text-black rounded-md'>{description}</P>
                        <div className='p-3 flex flex-col gap-4  rounded-b-md'>
                            {tag?.map((t, index) => (
                                <IconTexeLine size='s' key={index} className='mb-3' description={t.text}
                                              path={"/plus.png"}/>
                            ))}
                            <P size='s' className='mb-4'>{fullText}</P>
                            {price && <P size='l' className='mb-4 text-secondary'>
                                <span className={'font-light'}>Цена:</span>
                                {price}
                            </P>}
                            {children}
                        </div>
                    </div>
                    {(!children &&
                        <Button id={'button-service'} onClick={handleClick} size='lg' variant='outline'
                                className='shadow-custom'>Отправить заявку</Button>)}
                </div>
            </div>
        </div>
    );
});


CardService.displayName = 'CardService';
export default CardService;
   
        