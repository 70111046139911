import {cn} from '@/lib/utils';
import {P} from './ui/P';
import {Icon} from './ui/icon';
import Link from 'next/link';
import {useContext} from "react";
import {PopupContext} from "@/context/PopupContext";

export interface CardSmollProps {
    className?: string;
    cards: CardsSmoll
    onCardClick?: () => void;
}

export interface CardsSmoll {
    path: string;
    description: string;
    alt: string;
    title: string;
    link?: string;
    action?: string
}

export default function CardSmoll({className, cards, onCardClick}: CardSmollProps) {
    const {openPopup} = useContext(PopupContext);

    const handleClick = () => {
        if (cards.action !== undefined) {
            if(cards.action === 'scroll'){
                if(onCardClick) {
                    return onCardClick();
                }
            }
            openPopup(cards.action);
        }
    };

    const content = (
        <div onClick={handleClick}
             className={cn('ml-1 cursor-pointer flex flex-col w-30 h-40 shadow-custom rounded-lg place-content-center p-6 xl:w-72 lg:w-72 md:h-44 hover:bg-gray-50', className)}>
            <div className='flex flex-col gap-1 items-center lg:flex-row lg:gap-2'>
                <div className='bg-secondary p-2 rounded-full'>
                    <Icon size='l' path={cards.path} alt={cards.alt} className='hover:border-primary/80'/>
                </div>
                <P className='text-center tracking-wide lg:text-start' size={'l'}>
                    {cards.title}
                </P>
            </div>
            <P className='hidden mt-4 lg:block' size={'s'}>
                {cards.description}
            </P>
        </div>
    );

    return cards.link ? <Link href={cards.link}>{content}</Link> : content;
}