'use client'
import React, {useEffect, useState} from "react";
import {CardFlat, Flat} from "@/components/card-flat";
import {Htag} from "@/components/ui/htag";
import Filter from "@/components/filter";
import Link from "next/link";
interface FidsProps {
    pagination: number;
}
export const Fids: React.FC<FidsProps> = ({ pagination }) => {

    const [flats, setFlats] = useState<Flat[]>([]);

    useEffect(() => {
        const fetchFlats = async () => {
            try {
                const response = await fetch('https://murmanclick.ru/api/ads/fids');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data: Flat[] = await response.json();
                console.log('Fetched data:', data);
                setFlats(data);
            } catch (err) {

            }
        };
        fetchFlats();
    }, []);


    const [sortBy, setSortBy] = useState('date');

    const handleSortChange = (sortBy: string) => {
        setSortBy(sortBy);
        sortFlats(sortBy);
        sortFlats(sortBy);
    };

    const sortFlats = (sortBy: string) => {
        const sortedFlats = [...flats];
        if (sortBy === 'price') {
            sortedFlats.sort((a, b) => parseFloat(a.price.replace(/\D/g, '')) - parseFloat(b.price.replace(/\D/g, '')));
        } else {
            sortedFlats.sort((a, b) => a.id - b.id);
        }
        setFlats(sortedFlats);
    };
    return (
        <div className={'h-full mx-auto max-w-custom flex flex-col gap-8'}>
            <div className={'mx-3 mb-2'}>
                <Htag tag={'h2'} className={'mb-5'}>Объекты в продаже</Htag>
                {/*<Filter onSortChange={handleSortChange}/>*/}
            </div>
            <div className={'w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-auto gap-4'}>
                {flats.slice(0, pagination).map(flat => (
                    <Link key={flat.id} href={`/flat/${flat.id}`}>
                        <CardFlat key={flat.id} {...flat} />
                    </Link>
                ))}
            </div>
            {/*<Button variant={'outline'} className={'mx-auto'}>Смотреть еще</Button>*/}
        </div>
    );
};
