import {PopupData} from "@/intefaces/popup.prop";
import {Htag} from "./ui/htag";
import {Button} from "./ui/button";
import {P} from "@/components/ui/P";
import {Icon} from "@/components/ui/icon";
import Link from "next/link";
import SimplePhoneForm from "@/components/form-only-phone";
import React from "react";

interface PopupProps {
    data: PopupData;
    isOpen: boolean;
    onClose: () => void;
}

export const Popup = ({isOpen, onClose, data}: PopupProps) => {

    if (!isOpen) {
        // console.log("Popup is closed");
        return null;
    }
    if (isOpen) {
        // console.log("Popup is open");
    }
    return (
        <div
            className="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 flex justify-center items-center w-full h-full p-3"
            onClick={onClose}>
            <div className="relative flex flex-col gap-6 bg-white rounded-lg max-w-lg mx-auto p-6"
                 onClick={(e) => e.stopPropagation()}> {/* Добавлено остановление всплывания событий */}
                <div className={'flex flex-col items-start text-start gap-4'}>
                    <Htag tag={'h2'}>{data.discription}</Htag>
                    <Link href={'tel: +79113007705'}
                          className={`flex items-center justify-center gap-3 hover:text-primary`}
                    >
                        <Icon path={'/call3.svg'} size={'l'} alt={'alt'}
                              className={'border-secondary border rounded-full h-7 w-7 p-1'}/>
                        +7(8152) 707-705
                    </Link>
                    <P size={'s'} className={'my-4'}>{data.title}</P>

                    <Button onClick={onClose} size='icon' className="absolute bg-white top-3 right-2">
                        <Icon path={"/close.svg"} size='m' alt={'alt'}/>
                    </Button>
                </div>
                <SimplePhoneForm className={''}/>
                <p className={'text-xs'}>Отправляя заявку, Вы даете согласие на обработку персональных
                    данных и соглашаетесь c <Link href={'/'} className={'text-gray-500'}> политикой
                        конфиденциальности</Link>
                </p>
            </div>
        </div>
    );
};

