'use client';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Icon} from './ui/icon';
import {P} from './ui/P';
import AnalyzeResult from './analyze-result';
import ErrorAnalyze from './error';
import AnalyzeForm from './form-analyze';
import {Htag} from './ui/htag';
import {ResponseAnalyze} from '@/intefaces/api.props';
import {PopupContext} from "@/context/PopupContext";

export default function AnalyzeBlock() {
    const { setAfterActionPopup } = useContext(PopupContext);

    const [analize, setAnalyze] = useState<ResponseAnalyze | null>(null)

    const addressRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState<Error | null>(null);

    const [address, setAddress] = useState<string>('');

    const analyzedRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (analize) {
            setAfterActionPopup(true);
            if (analyzedRef.current) {
                analyzedRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        }
    }, [analize]);

    return (
        <>
            <div className='max-w-custom mx-auto mb-14 px-3'>
                <Icon size={'l'} path={"/fire.png"} alt={"Оценка квартир в Мурмнаске"} className="mb-2"/>
                <Htag tag={"h2"}>Узнайте рыночную стоимость бесплатно</Htag>
                <P className='mt-3'>Полный анализ цен и их динамики за последние 12 месяцев. Что бы понимать на что
                    расчитывать при покупке или продаже!</P>
                <AnalyzeForm
                    ref={addressRef}
                    setAnalyze={setAnalyze}
                    setError={setError}
                    setAddress={setAddress}/>
            </div>
            {error && <ErrorAnalyze error={error} address={address}/>}
            <div ref={analyzedRef}>
                {analize && <AnalyzeResult {...analize}/>}
            </div>
        </>
    );
}
