import React, {useContext} from 'react';
import CardSmoll, {CardsSmoll} from './card-smoll';
import {Htag} from './ui/htag';
import {Button} from './ui/button';
import {P} from './ui/P';
import {PopupContext} from "@/context/PopupContext";

interface Page {
    title: string;
    description: string;
    btname: string;
    cards: CardsSmoll[]
    onCardClick: () => void;
}

export default function Start({title, description, btname, cards, onCardClick}: Page) {
    const {openPopup} = useContext(PopupContext);


    const handleClick = () => {
        const pathname = window.location.pathname;
        let pathSegment = pathname.split('/').pop(); // Извлекаем последний сегмент пути
        if (!pathSegment || pathSegment === '/') {
            pathSegment = 'about';
        }
        console.log("Путь:", pathSegment);
            console.log("Id найденного элемента:", pathSegment);
            openPopup(pathSegment);
    };

    return (
        <div className='max-w-custom mx-auto'>
            <div className='flex flex-col gap-10 md:justify-between md:flex-row'>
                <div className=' flex flex-col gap-5 max-w-[350px] xl:max-w-xl'>
                        {/*{isAbout && <P> Агнтство недвижимости </P>}*/}
                    <Htag className="font-bold leading-tight" tag={"h1"}>{title}</Htag>
                    <P className='leading-8 text-black'> {description} </P>
                    <Button onClick={handleClick} className="hidden md:block shadow-custom mt-8">{btname}</Button>
                </div>
                <div className='hidden grid-cols-2 place-content-center gap-4 md:grid  xl:grid-cols-2 lg:grid-cols-2'>
                    {cards?.map((cards, index) => (
                        <CardSmoll
                            cards={cards}
                            key={index}
                            onCardClick={onCardClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
